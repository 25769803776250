<template>
  <BModal
    id="modal-warning"
    ref="modal-warning"
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    centered
    hide-header
  >
    <BCol
      md="12"
      class="d-flex justify-content-center pt-3"
    >
      <b-img
        width="100"
        src="@core/assets/image/icon-popup-warning.png"
      />
    </BCol>
    <BCol class="text-center mt-2">
      <h4 class="text-black text-xl">
        <strong>Anda yakin menghapus data rekening</strong>
      </h4>
    </BCol>
    <div class="text-center my-2">
      <BButton
        variant="outline-primary"
        class="mr-1"
        @click="closeModal"
      >
        Tidak
      </BButton>
      <BButton
        variant="primary"
        @click="onFinish"
      >
        <BSpinner
          v-if="loadingSubmit"
          variant="light"
          small
        />
        Ya
      </BButton>
    </div>
  </BModal>
</template>

<script>
import {
  BModal, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import provider from '@/provider/provider'
import { DELETE_BANK_ACCOUNT } from '@/provider/url'
import secureLocalStorage from '@/libs/secureLocalstorage'

export default {
  components: {
    BModal, BCol, BButton, BSpinner,
  },
  data() {
    return {
      loadingSubmit: false,
      idBank: secureLocalStorage.get('rekening').id,
    }
  },
  methods: {
    async onFinish() {
      this.loadingSubmit = true
      await provider.delete(`${DELETE_BANK_ACCOUNT}/${this.idBank}`)
        .then(() => {
          this.loadingSubmit = false
          this.$bvModal.hide('modal-warning')
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Berhasil',
              icon: 'CheckIcon',
              text: 'Data rekening anda berhasil di hapus',
              variant: 'success',
            },
          }, 2000)
          this.$router.push({ path: '/rekening-bank' })
        })
        .catch(() => {
          this.loadingSubmit = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal menghapus bank',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    closeModal() {
      this.$bvModal.hide('modal-warning')
    },
  },
}
</script>
