<template>
  <BCard>
    <BRow>
      <BButton
        variant="primary"
        class="mx-1"
        size="sm"
        tag="router-link"
        :to="{ name: 'rekening-bank' }"
      >
        <feather-icon
          icon="ChevronLeftIcon"
          size="15"
        />
      </BButton>
      <strong class="text-black font-semibold text-2xl">Edit Rekening Bank</strong>
    </BRow>
    <BCard class="cardRek mt-2">
      <div class="flex justify-between items-center mb-1">
        <p class="text-black text-xl font-semibold m-0">
          Rekening {{ idx }}
        </p>
      </div>
      <ValidationObserver>
        <BForm @submit.prevent="sendOtp">
          <BFormGroup label="Nama Bank*">
            <ValidationProvider rules="required">
              <VSelect
                v-model="data.bank_name"
                label="name"
                :reduce="option => option.code"
                :options="banks"
                :filterable="true"
                style="width: 30%"
                class="bankName"
                @input="getDataBank"
              />
            </ValidationProvider>
          </BFormGroup>
          <BFormGroup label="Nomor Rekening*">
            <ValidationProvider rules="required">
              <BFormInput
                v-model="data.bank_no"
                style="width: 30%"
                type="number"
                @input="changeRekeningNumber"
                @keypress="isNumber"
              />
            </ValidationProvider>
          </BFormGroup>
          <BFormGroup label="Nama*">
            <ValidationProvider rules="required">
              <BFormInput
                v-model="data.bank_owner_name"
                style="width: 30%"
                @input="changeRekeningName"
                @keypress="isAlphabet"
              />
            </ValidationProvider>
          </BFormGroup>
          <BRow class="justify-end mr-1">
            <BButton
              v-BModal="'modal-warning'"
              variant="outline-primary"
              class="mr-1"
            >
              Hapus
            </BButton>
            <BButton
              v-BModal="'modal-verification'"
              variant="primary"
              :disabled="rekeningName === '' || rekeningNumber === ''"
              type="submit"
            >
              <BSpinner
                v-if="loading"
                variant="light"
                small
              />
              Simpan
            </BButton>
          </BRow>
        </BForm>
      </ValidationObserver>
    </BCard>

    <ModalVerification
      :send-otp="sendOtp"
      :count-otp="countOtp"
      :expired-otp="expiredOtp"
      :source="source"
      :rekening-number="rekeningNumber"
      :rekening-name="rekeningName"
      :bank-name="bankName"
      :loading="loading"
    />
    <ModalDelete />
  </BCard>
</template>

<script>
import { BCard, BButton, BRow } from 'bootstrap-vue'
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import secureLocalStorage from '@/libs/secureLocalstorage'
import provider from '@/provider/provider'
import { SMS_OTP } from '@/provider/url'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { isNumber, isAlphabet } from '@/libs/helpers'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import ModalVerification from './ModalVerification.vue'
import ModalDelete from './ModalDelete.vue'

export default {
  components: {
    BCard, BButton, BRow, VSelect, ModalVerification, ModalDelete, ValidationObserver, ValidationProvider,
  },
  data() {
    return {
      data: secureLocalStorage.get('rekening'),
      banks: [],
      rekeningNumber: null,
      rekeningName: null,
      loading: false,
      phoneNumber: this?.$store?.state?.auth?.userData?.mitras?.phone_number,
      source: 'edit',
      countOtp: 0,
      expiredOtp: 0,
      bankName: '',
      isNumber,
      isAlphabet,
      idx: this.$route.params.id,
      required,

    }
  },
  mounted() {
    this.getDataBank()
  },
  methods: {
    async getDataBank(bankName) {
      this.bankName = bankName
      const url = 'xendit/disbursementbankAvailable'
      await this.$http.get(url)
        .then(res => {
          const { data } = res.data
          this.banks = data
        })
    },
    async sendOtp() {
      this.loading = true
      const datas = new FormData()
      const dataBody = [
        {
          name: 'phone_number',
          value: this.phoneNumber,
        },
        {
          name: 'session',
          value: 'rekening',
        },
      ]
      dataBody.map(item => datas.append(item.name, item.value))

      await provider.insert({
        url: SMS_OTP,
        body: datas,
      })
        .then(res => {
          const { data } = res
          this.countOtp = data.expired_at
          this.expiredOtp = data.check_request_otp
          this.loading = false
          this.countDownTimerOtp()
        })
        .catch(() => {
          this.loading = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal kirim OTP, silahkan coba lagi',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    changeRekeningNumber(number) {
      this.rekeningNumber = number
    },
    changeRekeningName(name) {
      this.rekeningName = name
    },
    countDownTimerOtp() {
      if (this.countOtp > 0) {
        setTimeout(() => {
          this.countOtp -= 1
          this.countDownTimerOtp()
        }, 1000)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import './Rekening.scss'
</style>
